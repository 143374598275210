<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleNo">
                <a-input v-model="queryParam.vehicleNo" placeholder="请输入车辆编号" allow-clear />
              </a-form-item>
              <!-- <a-form-model-item label="车辆编号" prop="vehicleNo">
                <a-select
                  show-search
                  :disabled="onlyRead"
                  allowClear
                  placeholder="请输入车辆自编号模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleVehicleNoSearch"
                  @change="handleVehicleNoChange"
                  :loading="selectLoading"
                  v-model="queryParam.vehicleNo"
                >
                  <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo">
                    {{ d.vehicleNo }}
                  </a-select-option>
                </a-select>
              </a-form-model-item> -->
            </a-col>
            <a-col :md="8" :sm="24">
              <!-- <a-form-item label="车牌号" prop="licensePlateNum">
                <a-input v-model="queryParam.licensePlateNum" placeholder="请输入车牌号" allow-clear />
              </a-form-item> -->
              <a-form-model-item label="车牌号" prop="licensePlateNum">
                <a-select
                  show-search
                  :disabled="onlyRead"
                  allowClear
                  placeholder="请输入车辆车牌号模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleLicensePlateNumSearch"
                  @change="handleLicensePlateNumChange"
                  :loading="selectLoading"
                  v-model="queryParam.licensePlateNum"
                >
                  <a-select-option v-for="d in vehicleNoArray" :key="d.licensePlateNum">
                    {{ d.licensePlateNum }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="卡号" prop="cardNo">
                  <a-input v-model="queryParam.cardNo" placeholder="请输入卡号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="主卡号" prop="mainCardNo">
                  <a-input v-model="queryParam.mainCardNo" placeholder="请输入所属主卡卡号" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="选择日期">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeStartDate"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="种类" prop="accountType">
                  <a-select
                    placeholder="请选择查询种类"
                    v-model="queryParam.accountType"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in accountTypeOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn">
                  <a-icon type="search" />查询
                </a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"> <a-icon type="redo" />重置 </a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button
          type="primary"
          @click="$refs.rechargeForm.handleAdd('充值申请')"
          v-hasPermi="['tenant:iot:oilAccountRecord:add']"
        >
          充值
        </a-button> -->
          <a-button
            type="primary"
            @click="$refs.transferForm.handleAdd('划拨')"
            v-hasPermi="['tenant:iot:oilAccountRecord:transfer']"
          >
            划拨
          </a-button>
          <a-button
            type="primary"
            @click="$refs.cancelForm.handleAdd('核销')"
            v-hasPermi="['tenant:iot:oilAccountRecord:cancelVerification']"
          >
            核销
          </a-button>
          <!-- <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tenant:iot:oilAccountRecord:remove']"
        >
          <a-icon type="delete" />删除
        </a-button> -->
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:oilAccountRecord:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <cancel-form ref="cancelForm" @ok="getList" />
      <transfer-form ref="transferForm" @ok="getList" />
      <refuel-form ref="refuelForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="accountType" slot-scope="text, record">
          {{ accountTypeMap ? accountTypeMap[record.accountType] : '' }}
        </span>
        <span
          slot="bindCard"
          slot-scope="text, record"
          :title="
            record.accountType === 'recharge'
              ? '主卡不可绑定车辆'
              : record.licensePlateNum || record.vehicleNo
                ? (record.licensePlateNum || '-') + '|' + record.vehicleNo
                : '未绑定车辆'
          "
        >
          {{
            record.accountType === 'recharge'
              ? '主卡不可绑定车辆'
              : record.licensePlateNum || record.vehicleNo
                ? (record.licensePlateNum || '-') + '|' + record.vehicleNo
                : '未绑定车辆'
          }}
        </span>
        <span slot="updateTime" slot-scope="text, record">
          {{ record.accountType === 'transfer' ? record.createTime : record.updateTime || record.createTime }}
        </span>
        <span slot="approvalStatus" slot-scope="text, record" :title="approvalStatusFormat(record)">
          {{ approvalStatusFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <!-- <a-divider type="vertical" v-hasPermi="['iot:oilAccountRecord:edit']" /> -->
          <a @click="toView(record)"> 查看 </a>
          <!-- <a
            @click="handleReject(record)"
            v-hasPermi="['tenant:iot:expenses:remove']"
            v-if="record.approvalStatus <= 1"
          >
            <a-icon type="rollback" />撤回
          </a> -->
          <a v-if="record.accountType === 'recharge'" @click="toPrint(record)"> <a-icon type="info-circle" />打印 </a>
          <button style="display: none" ref="printBtn" v-print="printObj">x</button>
        </span>
      </a-table>
      <div style="display: none">
        <pOliAccount @readyPrint="readyPrint" :data="oliAccountData"></pOliAccount>
      </div>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <approval-detail ref="approvalDetail" @ok="getList" />
      <!-- <approval ref="approval" :form="approvalForm" :open="open" :approvalInfoList="approvalInfoList" :purchaseList="purchaseList" @ok="getList" /> -->
    </a-card>
  </page-header-wrapper>
</template>
<script>
import { listOilAccountRecord, delOilAccountRecord, exportOilAccountRecord } from '@/api/iot/oilAccountRecord'
import cancelForm from './modules/cancelForm'
import transferForm from './modules/transferForm'
import debounce from 'lodash/debounce'
import refuelForm from './modules/oliForm'
import { searchCarNo } from '@/api/iot/vehicleAccount'
import pOliAccount from '@/views/printouts/oliAccount.vue'
// import approval from '@/components/Approval/index.vue'
import approvalDetail from '@/views/approval/approvalDetail'

export default {
  name: 'OilAccountRecord',
  components: {
    cancelForm,
    transferForm,
    refuelForm,
    pOliAccount,
    approvalDetail
  },
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    this.handleLicensePlateNumSearch = debounce(this.handleLicensePlateNumSearch, 500)
    return {
      expenseId: '',
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        cardNo: null,
        approvalStatus: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '账务类型',
          dataIndex: 'accountType',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'accountType' }
        },
        {
          title: '主卡号',
          // dataIndex: 'mainCardNo',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.accountType === 'recharge') {
              return record.cardNo
            } else if (record.accountType === 'cancel_verification') {
              // 当mainCardNo为0时代表是主卡，否则为分卡
              if (record.mainCardNo === '0') {
                return record.cardNo
              } else {
                return record.mainCardNo
              }
            } else {
              return record.mainCardNo
            }
          }
        },
        {
          title: '分卡号',
          // dataIndex: 'cardNo',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.accountType === 'recharge') {
              return '-'
            } else if (record.accountType === 'cancel_verification') {
              // 当mainCardNo为0时代表是主卡，否则为分卡
              if (record.mainCardNo === '0') {
                return '-'
              } else {
                return record.cardNo
              }
            } else {
              return record.cardNo
            }
          }
        },
        {
          title: '已绑定车辆（车牌|自编号）',
          ellipsis: true,
          scopedSlots: { customRender: 'bindCard' },
          align: 'center'
        },
        {
          title: '发生金额',
          dataIndex: 'amount',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (!record.amount) {
              return ''
            }
            if (record.accountType === 'recharge') {
              return '+' + record.amount
            } else if (record.accountType === 'cancel_verification') {
              if (record.cancelVerificationType === 2) {
                return '+' + record.amount
              } else {
                return '-' + record.amount
              }
            } else {
              return '-' + record.amount
            }
          }
        },
        {
          title: '划拨金额',
          dataIndex: 'amountTransfer',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '主卡可用余额',
          dataIndex: 'balanceMainCard',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '分卡可用余额',
          dataIndex: 'balanceBranchCard',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '责任人',
          dataIndex: 'inchargeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审批状态',
          dataIndex: 'approvalStatus',
          scopedSlots: { customRender: 'approvalStatus' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '生效时间',
          scopedSlots: { customRender: 'updateTime' },
          dataIndex: 'updateTime',
          ellipsis: true,
          align: 'center',
          width: 150
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '8%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      daterangeStartDate: [],
      accountTypeMap: {},
      selectLoading: false,
      vehicleNoArray: [],
      approvalStatusTypeOptions: [],
      printObj: {
        id: 'oliAccountPrint',
        popTitle: '充值',
        // extraCss:
        //   'https://cdn.bootcdn.net/ajax/libs/animate.css/4.1.1/animate.compat.css, https://cdn.bootcdn.net/ajax/libs/hover.css/2.3.1/css/hover-min.css',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        beforeOpenCallback(vue) {
          vue.printLoading = true
          console.log('打开之前')
        },
        openCallback(vue) {
          vue.printLoading = false
          console.log('执行了打印')
        },
        closeCallback(vue) {
          console.log('关闭了打印工具')
        }
      },
      oliAccountData: {},
      approvalInfoList: [],
      purchaseList: [],
      open: false,
      approvalForm: {}
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDictData()
  },
  computed: {},
  watch: {},
  methods: {
    async getDictData() {
      this.loading = true
      await this.getDicts('iot_account_type').then((response) => {
        this.accountTypeOptions = response.data
        for (const item of this.accountTypeOptions) {
          this.accountTypeMap[item.dictValue] = item.dictLabel
        }
        this.$forceUpdate()
      })
      await this.getDicts('iot_approval_status_type').then((response) => {
        this.approvalStatusTypeOptions = response.data
      })
      this.loading = false
    },
    /** 查询油卡账务记录列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (this.daterangeStartDate && this.daterangeStartDate.length > 0) {
        this.queryParam.params['beginStartDate'] = this.daterangeStartDate[0]
        this.queryParam.params['endStartDate'] = this.daterangeStartDate[1]
      }
      if (this.daterangeEndDate && this.daterangeEndDate.length > 0) {
        this.queryParam.params['beginEndDate'] = this.daterangeEndDate[0]
        this.queryParam.params['endEndDate'] = this.daterangeEndDate[1]
      }
      listOilAccountRecord(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        cardNo: undefined,
        approvalStatus: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.daterangeStartDate = []
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delOilAccountRecord(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportOilAccountRecord(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    approvalStatusFormat(row, column) {
      return this.selectDictLabel(this.approvalStatusTypeOptions, row.approvalStatus)
    },
    handleView(record) {
      console.log('record', record)
      if (record.accountType === 'recharge') {
        const approvalRecord = {
          // 发起人
          promoter: record.createBy,
          // 审批模块
          approvalKind: 'recharge',
          // 费用记录ID
          approvalKindId: record.id,
          // 审批状态
          approvalStatus: record.approvalStatus,
          // 审批流程Key
          processInstanceId: record.instanceId
        }
        this.$refs.approvalDetail.handleDetail(approvalRecord)
      }
    },
    toView(record) {
      switch (record.accountType) {
        case 'recharge':
          const approvalRecord = {
            // 发起人
            promoter: record.createBy,
            // 审批模块
            approvalKind: 'recharge',
            // 费用记录ID
            approvalKindId: record.id,
            // 审批状态
            approvalStatus: record.approvalStatus,
            // 审批流程Key
            processInstanceId: record.instanceId
          }
          this.$refs.approvalDetail.handleDetail(approvalRecord)
          break
        case 'transfer':
          this.$refs.transferForm.handleAdd('划拨', record)
          break
        case 'refuel':
          this.$refs.refuelForm.handleAdd('加油', record)
          break
        case 'cancel_verification':
          this.$refs.cancelForm.handleAdd('核销', record)
          break
      }
    },
    toPrint(record) {
      console.log('toPrint', record)
      this.oliAccountData = record
      this.$refs.printBtn.click()
    },
    readyPrint() {
      this.$refs.printBtn.click()
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const queryParam = {
        vehicleNo: value
      }
      this.selectLoading = true
      searchCarNo(queryParam).then((response) => {
        this.vehicleNoArray = response
        this.selectLoading = false
      })
    },
    handleVehicleNoChange(value) {
      this.form.vehicleNo = value
      // 设置车牌号
      const tmpArray = this.vehicleNoArray.filter((p) => p.vehicleNo === value)
      if (tmpArray.length > 0) {
        this.form.licensePlateNum = tmpArray[0].licensePlateNum
        this.$forceUpdate()
      }
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      const queryParam = {
        licensePlateNum: value
      }
      this.selectLoading = true
      searchCarNo(queryParam).then((response) => {
        this.vehicleNoArray = response
        this.selectLoading = false
      })
    },
    handleLicensePlateNumChange(value) {
      this.form.licensePlateNum = value
      // 设置车辆自编号
      // const tmpArray = this.vehicleNoArray.filter(p => p.licensePlateNum === value)
      // if (tmpArray.length > 0) {
      //   this.form.vehicleNo = tmpArray[0].vehicleNo
      //   this.$forceUpdate()
      // }
    }
  }
}
</script>
